<template>
  <b-row>
    <b-col>
      <b-col cols="3">
        <b-form-group
          label="차트 표현 종류"
          label-for="boxplotChartSelect"
        >
          <v-select
            id="boxplotChartSelect"
            v-model="filterSelected.BOXPLOT"
            label="label"
            :options="options"
            placeholder="선택"
            @input="searchBoxPlotChart($event)"
          />
        </b-form-group>
      </b-col>
      <!--      <div style="display: none;">-->
      <!--      가져온 데이터 > {{ series }} <br>-->
      <!--      </div>-->
      <vue-apex-charts
        type="boxPlot"
        height="400"
        :options="chartOptions"
        :series="boxplotData"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from "vue-select";
import { BRow, BCol, BFormGroup } from "bootstrap-vue";
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    VueApexCharts,
  },
  data() {
    return {
      options: [
        {
          label: "단열 기준",
          cdGrpId: "NPMS018",
          columnId: "INSLT_ST_CD"
        },
        {
          label: "연면적 구분",
          cdGrpId: "NPMS006",
          columnId: "TOTAREA_CD"
        },
        {
          label: "연식 기준",
          cdGrpId: "NPMS019",
          columnId: "ANUAL_ST_CD"
        },
        {
          label: "기후지역",
          cdGrpId: "NPMS004",
          columnId: "KMA_AREA_CD"
        },
        {
          label: "주용도",
          cdGrpId: "NPMS001",
          columnId: "MAIN_PURPS_CD"
        },
        {
          label: "에너지원",
          cdGrpId: "NPMS010",
          columnId: "ENGY_KIND_CD"
        },
      ],
      chartOptions: {
        chart: {
          type: 'boxPlot',
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          boxPlot: {
            colors: {
              upper: '#826af9',
              lower: '#f8d3ff'
            }
          }
        }
      },
    }
  },
  computed: {
    ...mapGetters({
      filterSelected: "filter/getFilterFetchedInfo",
      boxplotData: "filter/getBoxplotData",
    })
  },
  methods: {
    async searchBoxPlotChart(event) {
      if (event !== null && event.length !== 0) {
        // 차트 데이터 조회
        const selectedFilterData = this.filterSelected;
        await this.$store.dispatch("filter/FETCH_BOXPLOT_DATA", selectedFilterData);
      }
    }
  }
};

</script>
